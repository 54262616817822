/*
    The Spreedly modal has a different orientation on
    screens in landscape up to 480px wide (mebile devices)
*/
@media screen and (max-width: 480px) {
    div.spreedly-fields-holder {
        height: auto;
    }

    fieldset.spreedly-fs-name {
        height: auto;
    }

    fieldset.spreedly-fs-cc {
        height: auto;
    }

    fieldset.spreedly-fs-cc .spreedly-field {
        height: auto;
        padding: 5px 5px 5px 16px;
    }

    label.spreedly-label {
        width: 65px;
    }

    div#spreedly-number {
        max-width: 200px;
        padding: 12px 12px 10px 10px;
        border: 2px solid transparent;
    }

    fieldset.spreedly-fs-name div.spreedly-field input#spreedly-name,
    fieldset.spreedly-fs-cc div.spreedly-field input#spreedly-exp-month,
    fieldset.spreedly-fs-cc div.spreedly-field input#spreedly-exp-year {
        height: auto;
        padding: 12px;
        font-size: 16px;
    }

    fieldset.spreedly-fs-cc div.spreedly-field input#spreedly-exp-month,
    fieldset.spreedly-fs-cc div.spreedly-field input#spreedly-exp-year {
        position: relative;
        padding-right: 5px;
        padding-left: 5px;
        max-width: 40px;
        font-variant-numeric: tabular-nums;
        letter-spacing: 2px;
        text-align: center;
    }

    fieldset.spreedly-fs-cc div.spreedly-field input#spreedly-exp-month {
        margin-left: 5px;
    }

    fieldset.spreedly-fs-cc div.spreedly-field input#spreedly-exp-year {
        margin-left: 3px;
    }

    fieldset.spreedly-fs-cc div.spreedly-field input#spreedly-exp-month::placeholder,
    fieldset.spreedly-fs-cc div.spreedly-field input#spreedly-exp-year::placeholder {
        letter-spacing: 1px;
    }


    fieldset.spreedly-fs-name div.spreedly-field input#spreedly-name:focus,
    fieldset.spreedly-fs-cc div.spreedly-field input#spreedly-exp-month:focus,
    fieldset.spreedly-fs-cc div.spreedly-field input#spreedly-exp-year:focus {
        outline-color: #97cdff;
    }

    fieldset.spreedly-fs-cc div.spreedly-field i.spreedly-icon-cc {
        right: 55px;
    }

    div#spreedly-cvv {
        padding: 12px 12px 10px 10px;
        border: 2px solid transparent;
    }

    div.spreedly-field i.spreedly-icon-validation {
        margin-top: -8px;
    }

    div.spreedly-field i.spreedly-icon-cc {
        margin-top: 4px;
    }

    #spreedly-submit-button {
        padding: 0;
        font-size: 18px;
    }

    .focusSpreedlyParent {
        box-sizing: border-box !important;
        outline: none;
        border-width: 2px !important;
        border-style: solid !important;
        border-color: #97cdff !important;
        border-radius: 4px !important;
    }
}

@media screen and (min-width: 481px) {
    [name*="spreedly-cvv-frame"] {
        margin-top: -2px !important;
        height: calc(100% + 2px) !important;
    }
}