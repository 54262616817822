.react-bootstrap-table-pagination {
    div:first-child {
        display: none;
    }
    .react-bootstrap-table-pagination-list {
        width: 100% !important;
        max-width: 100% !important;
        flex: 0 0 100% !important;
        text-align: center;
        ul.pagination {
            width: auto;
            display: inline-flex;
            li {
                margin: 0 0.25rem;
                a {
                    background: transparent;
                    border: none;
                    color: $vitusPrimaryBlue;
                    font-family: 'Lato-Heavy', sans-serif;
                    font-size: 15px;
                    border-radius: 100%;
                    padding: .2rem .5rem;
                }
                &.active {
                    a {
                        color: #FFF;
                        border: 1px solid $vitusPrimaryOrange;
                        background-color: $vitusPrimaryOrange;
                    }
                }
            }
        }
    }
}