.vitus-date-range-picker {
    display: flex;
    flex-direction: column;
    background: white;

    &.error {
        .vitus-date-range-picker-label {
            color: $vitusRedColor;
        }

        .vitus-date-range-picker-container { 
            border-color: $vitusRedColor !important;
        }

        .error-message {
            color: $vitusRedColor;

            &.icon-padding {
                padding-left: 22px;
            }
        }
    }

    .vitus-date-range-picker-container {
        display: inline-flex;
        align-items: center;
        border: 1px solid $vitusDetailBlue;
        border-radius: 5px;
        padding: .1rem;
    }

    .vitus-date-range-picker-label {
        position: absolute;
        left: .5rem;
        top: -9px;
        padding: 0 .25rem;
        background: white;
        font-size: 13px;
        color: $vitusPrimaryOrange;
        z-index: 1;
    }

    .calendar-dropdown {
        cursor: pointer;
    }
}