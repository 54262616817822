@import 'assets/styles/_variables.scss', 'sharedStyles.scss';

.headings {
    margin: 1rem 1rem;

    display: grid;
    grid-template-columns: $basicDetailsGridColumnLayout;
    column-gap: $basicDetailsGridColumnGap;

    /*
        This is here to help the headings better align
        with the actual data
    */
    border: 5px solid transparent;

    p {
        margin-bottom: 0;
        color: $vitusPrimaryOrange;
        text-transform: uppercase;
        font-size: 14px;
        text-overflow: ellipsis;
    }
}

.clickable {
    cursor: pointer;
}
