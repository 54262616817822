/*
* It would have been preferable to use something like:
*      repeat(6, minmax(100px 2fr))
* But the auto scaling doesn't align everything in perfect columns at all
* screen sizes and I didn't want anyone to complain about the layout
* changing slightly ¯\_(ツ)_/¯
*/
$basicDetailsGridColumnLayout: 100px 100px 1fr 2fr 120px 120px 40px;
$basicDetailsGridColumnGap: 1rem;

$expandedDetailsPaymentOptionsGridAreas: 'contactEmail stripeId merchantFeePercentage .'
  'authNetLogin authNetPassword payFabricSetupId payFabricDeviceId'
  'installments installmentFeePercentage installmentsPlan installmentsMinAmount'
  'paymentLinkEnabled onlyVetshipRxPayments paymentLinkCustomMessage paymentLinkExpiration'
  'paymentPlatform vitusPayExclusive payWithSavedCards paymentLinkEmailUpdateEnabled'
  'invoiceWritebackEnabled bitwerxPracticeId . .';

$expandedDetailsPaxDeviceGridAreas: 'requireStaffMember requirePetOwnerName requireDescription requireRefundReason'
  'mobileSignatureCaptureMinimumAmount . . .';

// Payment options classes
.contactEmail {
  grid-area: contactEmail;
}

.stripeId {
  grid-area: stripeId;
}

.merchantFeePercentage {
  grid-area: merchantFeePercentage;
}

.authNetLogin {
  grid-area: authNetLogin;
}

.authNetPassword {
  grid-area: authNetPassword;
}

.payFabricSetupId {
  grid-area: payFabricSetupId;
}

.payFabricDeviceId {
  grid-area: payFabricDeviceId;
}

.installments {
  grid-area: installments;
}

.installmentFeePercentage {
  grid-area: installmentFeePercentage;
}

.installmentsPlan {
  grid-area: installmentsPlan;
}

.installmentsMinAmount {
  grid-area: installmentsMinAmount;
}

.paymentLinkEnabled {
  grid-area: paymentLinkEnabled;
}

.onlyVetshipRxPayments {
  grid-area: onlyVetshipRxPayments;
}

.paymentPlatform {
  grid-area: paymentPlatform;
}

.vitusPayExclusive {
  grid-area: vitusPayExclusive;
}

.paymentLinkCustomMessage {
  grid-area: paymentLinkCustomMessage;
}

.paymentLinkExpiration {
  grid-area: paymentLinkExpiration;
}

.payWithSavedCards {
  grid-area: payWithSavedCards;
}

.paymentLinkEmailUpdateEnabled {
  grid-area: paymentLinkEmailUpdateEnabled;
}

.invoiceWritebackEnabled {
  grid-area: invoiceWritebackEnabled;
}

.bitwerxPracticeId {
  grid-area: bitwerxPracticeId;
}

// Pax device classes
.requireStaffMember {
  grid-area: requireStaffMember;
}

.requirePetOwnerName {
  grid-area: requirePetOwnerName;
}

.requireDescription {
  grid-area: requireDescription;
}

.requireRefundReason {
  grid-area: requireRefundReason;
}

.mobileSignatureCaptureMinimumAmount {
  grid-area: mobileSignatureCaptureMinimumAmount;
}
