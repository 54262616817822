// Fonts
@font-face {
    src: url('./assets/fonts/Lato/Lato-Bold.ttf');
    font-family: 'Lato-Medium';
}

@font-face {
    src: url('./assets/fonts/Lato/Lato-Regular.ttf');
    font-family: 'Lato-Regular';
}

@font-face {
    src: url('./assets/fonts/Lato/Lato-Black.ttf');
    font-family: 'Lato-Heavy';
}

@font-face {
    src: url('./assets/fonts/Montserrat/Montserrat-Medium.ttf');
    font-family: 'Montserrat-Medium';
}


@font-face {
    src: url('./assets/fonts/Montserrat/Montserrat-SemiBold.ttf');
    font-family: 'Montserrat-SemiBold';
}

@font-face {
    src: url('./assets/fonts//Montserrat/Montserrat-Bold.ttf');
    font-family: 'Montserrat-Bold';
}


// Global Utils
@import './assets/styles/variables';
@import './assets/styles/utils';
@import './assets/styles/bootstrap-overrides';
@import './assets/styles/app-header';
@import './assets/styles/table';
@import './assets/styles/no-data';
@import './assets/styles/table-pagination';
@import './assets/styles/texts';
@import './assets/styles/buttons';
@import './assets/styles/react-dates-overrides';

// Spreedly
@import './assets/styles/spreedly-overrides';

// Login
@import './components/Login/styles/login';

// Sidebar
@import './components/Layout/SideBar/styles/sidebar';

// Layout
@import './components/Layout/layout';

// Common Components
@import './components/Common/SlimTable/styles/slim-table';
@import './components/Common/LinkedValue/styles/linkedValue';
@import './components/Common/Refresher/styles/refresher';
@import './components/Common/SortingHeader/styles/sortingHeader';
@import './components/Common/InputField/styles/inputField';
@import './components/Common/SelectField/styles/selectField';
@import './components/Common/DateRangePicker/styles/dateRangePicker';
@import './components/Common/ItemsTable/styles/itemsTable';

// Body
body {
    background: $vitusBackgroundGray;
    font-family: 'Montserrat-Medium', sans-serif;
    color: $vitusPrimaryBlue
}