.slim-table {
  color: $vitusPrimaryBlue;
  font-size: 14px;
  font-weight: 500;
  table-layout: auto !important;
  margin-top: 0.5rem;
  width: auto;

  thead {
    th {
      border: none;
      color: $vitusPrimaryOrange;
      font-size: 14px;
      font-weight: bold;
      padding: 0.5rem;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  tbody {
    max-height: 900px;
    overflow-y: scroll;
    tr {
      background-color: #fff;
      border-bottom: none;
      border-bottom-style: hidden;
      td {
        overflow: hidden;
        color: $vitusPrimaryBlue;
      }
    }
  }
}
