@import 'assets/styles/_variables.scss', 'sharedStyles.scss';

.basicDetailsGrid {
  display: grid;
  grid-template-columns: $basicDetailsGridColumnLayout;
  column-gap: $basicDetailsGridColumnGap;

  p {
    font-size: 14px;
    font-family: 'Montserrat-Medium', sans-serif;
    text-overflow: ellipsis;
  }
}

.chevron {
  position: relative;
  z-index: 5;
  padding: 0.2rem 0.5rem;

  justify-self: center;
  align-self: center;
  font-size: 14px;
  cursor: pointer;
}

.buttonContainer {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.moreDetails {
  overflow: hidden;
}

.expand {
  max-height: 100%;
}

.collapse {
  max-height: 0px;
}

.paymentOptionsGridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: $expandedDetailsPaymentOptionsGridAreas;
  column-gap: 1rem;
  row-gap: 1rem;

  p,
  span {
    margin-bottom: 0;
    font-size: 14px;
    font-family: 'Montserrat-Medium', sans-serif;
  }
}

.paxDeviceGridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: $expandedDetailsPaxDeviceGridAreas;
  column-gap: 1rem;
  row-gap: 1rem;

  p,
  span {
    margin-bottom: 0;
    font-size: 14px;
    font-family: 'Montserrat-Medium', sans-serif;
  }
}

.fieldHeader {
  color: $vitusPrimaryOrange;
  text-transform: uppercase;
}

.hr {
  border-color: $vitusPrimaryOrange;
}
