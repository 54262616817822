table.vitus-table {
    color: $vitusPrimaryBlue;
    font-size: 14px;
    font-weight: 600;
    table-layout: auto !important;
    border-collapse: separate;
    border-spacing: 0 0.5rem;
    margin-top: 0.5rem;
    thead {
        th {
            border: none;
            color: $vitusPrimaryOrange;
            font-size: 14px;
            font-weight: bold;
            padding: 0 0.7rem;
            text-align: left;
        }
        th[data-row-selection="true"] {
            i {
                display: none;
            }
        }
    }
    tbody {
        tr {
            border-radius: 5px;
            box-shadow: 0 3px 3px 1px rgba(0,0,0,0.2);
            background-color: #FFF;
            td:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
            td:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
            td {
                height: 58px;
                vertical-align: inherit;

                .actions {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    
                    .btn-link {
                        width: 54px;
                    }
                }

            }
        }
        tr.parent-expanded {
            td {
                border-top: 2px solid $vitusPrimaryOrange;
            }
            td:first-child {
                border-left: 2px solid $vitusPrimaryOrange;
            }
            td:last-child {
                border-right: 2px solid $vitusPrimaryOrange;
            }
        }
        tr.parent-expanded + tr {
            td.row-expanded {
                div.row-expand-slide-enter-done {
                    background: #FFF;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    border-bottom: 2px solid $vitusPrimaryOrange;
                    border-left: 2px solid $vitusPrimaryOrange;
                    border-right: 2px solid $vitusPrimaryOrange;
                    margin-top: -16px;
                    .row-expansion-style {
                        padding: 8px;
                        .table-details {
                            border-top: 1px solid $vitusPrimaryOrange;
                            .detail-header {
                                color: $vitusPrimaryOrange;
                                font-size: 11px;
                            }
                        }
                    }
                }
            }
        }
    }
}