@import 'assets/styles/_variables.scss';

/* ============= */
/* HTML Elements */
/* ============= */

label {
    position: relative;
    display: inline;
}

/* ================= */
/* Component Classes */
/* ================= */

.wrapper {
    box-sizing: border-box !important;
    position: relative;
    padding: 2px 2px 2px 5px;
    height: 30px;

    border: 1px solid $vitusSecondaryTextGray;
    border-radius: 2px;
    overflow: hidden;
}

.wrapperFocus {
    background-clip: border-box;
    border-radius: 5px;
    outline: auto #005fcc 2px;
    outline-offset: -1px;
}

.overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
    display: flex;
    align-items: center;
    padding-left: 5px;

    background: #fff;
    color: $vitusSecondaryTextGray;
}

.legend {
    flex: 1 0 100%;
    margin: 0;
    padding: 0;
    color: $vitusPrimaryOrange;
}

.slash {
    margin: 0 5px;
    color: $vitusSecondaryTextGray;
}

.placeholderGroup {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    background: #fff;
}

.placeholderGroup span {
    box-sizing: border-box;
    width: 1em;
    text-align: center;
    font-size: 16px;
    font-variant-numeric: tabular-nums;
    color: $vitusSecondaryTextGray;
    font-family: Arial, Helvetica, sans-serif;
}

.placeholderGroup span:not(:last-child) {
    margin-right: .1em;
}

.inputGroup {
    position: relative;
    z-index: 2;
}

.inputGroup input{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 1em;

    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-variant-numeric: tabular-nums;
    background: transparent;
    border: none;
}

.inputGroup input:focus {
    outline: none;
}

/*
    The SpreedlyForm component has the parent element
    that defines the grid template areas
*/
.dateLabel {
    grid-area: dateLabel;
}

.dateInput {
    grid-area: dateInput;
}

/* ============ */
/* Util Classes */
/* ============ */

.invalid {
    outline: solid 2px $vitusRedColor;
    outline-offset: -1px;
}