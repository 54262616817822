@import "src/assets/styles/_variables.scss";

.spin {
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.refreshButton {
  cursor: pointer;
}

.newDeviceFormTitle {
  font-size: 15px;
}

.successFeedback {
  color: $statusGreen;
}
