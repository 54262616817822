// Bootrap 4 overrides
body {
    position: relative;
}

button, a {
    &.btn-primary {
        background-color: $vitusPrimaryOrange;
        border-color: $vitusPrimaryOrange;

        &:hover,
        &:not(:disabled):not(.disabled):active,
        &.disabled,
        &.btn-primary:disabled {
            background-color: $vitusPrimaryOrange;
            border-color: $vitusPrimaryOrange;
            opacity: 0.8;
        }
    }

    &.btn-secondary {
        background-color: $vitusPrimaryBlue;
        border-color: $vitusPrimaryBlue;

        &:hover,
        &:not(:disabled):not(.disabled):active,
        &.disabled,
        &.btn-primary:disabled {
            background-color: $vitusPrimaryBlue;
            border-color: $vitusPrimaryBlue;
            opacity: 0.8;
        }
    }

    &.btn-outline-primary {
        color: $vitusPrimaryOrange;
        border-color: $vitusPrimaryOrange;

        &:hover,
        &:not(:disabled):not(.disabled):active,
        &.disabled,
        &.btn-primary:disabled {
            background-color: $vitusPrimaryOrange;
            border-color: $vitusPrimaryOrange;
        }
    }

    &.btn-outline-secondary {
        color: $vitusPrimaryBlue;
        border-color: $vitusPrimaryBlue;

        &:hover,
        &:not(:disabled):not(.disabled):active,
        &.disabled,
        &.btn-primary:disabled {
            background-color: $vitusPrimaryBlue;
            border-color: $vitusPrimaryBlue;
        }
    }

    &.btn-link {
        color: $vitusPrimaryOrange;

        &:hover,
        &:not(:disabled):not(.disabled):active,
        &.disabled,
        &.btn-primary:disabled {
            color: $vitusPrimaryOrange;
            filter: brightness(85%);
        }
    }

    &:disabled,
    &.disabled {
        cursor: not-allowed;
    }

}

.text-primary {
    color: $vitusPrimaryBlue !important;
}
.text-secondary {
    color: $vitusPrimaryOrange !important;
}

input[type="text"].form-control,
input[type="password"].form-control,
input[type="search"].form-control,
input[type="email"].form-control,
input[type="number"].form-control,
textarea.form-control {
    border-color: $vitusDetailBlue;
    background-color: #fafafa;
}

input[type="search"].form-control,
input[type="text"].form-control.search {
    background-image: url('./../images/search-solid.svg');
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: right 0.5rem center;
    &:focus {
        background-image: none;
    }
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
    color: #FFF;
}

.navbar-light .navbar-nav .nav-link {
    color: $vitusPrimaryOrange;
    background-color: $vitusBackgroundGray;
    &:hover {
        color: $vitusPrimaryBlue;
    }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #FFF;
    background-color: $vitusPrimaryOrange;
}

.badge-success,
.badge-error {
    color: #FFF !important;
}

.badge-warning {
    color: #212529 !important;
}

.input-group-text-invalid {
    border: 1px solid #dc3545;
}

.tooltip-inner {
    background-color: white;
    color: $vitusPrimaryBlue;
    box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.2);
}
.tooltip.bs-tooltip-right .arrow:before {
    border-right-color: white;
}
.tooltip.bs-tooltip-left .arrow:before {
    border-left-color: white;
}
.tooltip.bs-tooltip-bottom .arrow:before {
    border-bottom-color: white;
}
.tooltip.bs-tooltip-top .arrow:before {
    border-top-color: white;
}