@import '../../assets/styles/variables';

.toastContainer {
  position: fixed;
  top: 5%;
  right: 5%;
  z-index: 999;
}

.toast {
  border: 2px solid black;
}

.toastHeading {
  color: $vitusPrimaryBlue;
  font-weight: bold;
}

.toastBody {
  cursor: pointer;
}

.redirectButton {
  all: unset;
}

.redirectButton:focus {
  all: unset;
}
