.btn-outline-link {
    color: $vitusLinkBlue;
    border-color: $vitusLinkBlue;
}

.btn-outline-link:hover {
    color: white;
    background-color: $vitusLinkBlue;
}

.clickable {
    cursor: pointer;
}