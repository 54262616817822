@import 'assets/styles/_variables.scss';

/* ============= */
/* HTML Elements */
/* ============= */

h1 {
    margin-bottom: 1rem;
    text-align: center;
}

h2 {
    margin-bottom: 20px;
}

label {
    display: flex;
    flex-direction: column;
}

input {
    width: 100%;
}

footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* ================= */
/* Component Classes */
/* ================= */

.form {
    margin: 0 auto;
    padding: 2rem;
    min-height: inherit;
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
}

.amount {
    margin-bottom: 1rem;
    text-align: center;
    font-size: 26px;
    color: $vitusPrimaryOrange;
}

.amount > span{
    margin-left: 5px;
}

.spreedlyNumber,
.spreedlyCVV {
    padding: 2px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid $vitusSecondaryTextGray;
    border-radius: 2px;
}

.spreedlyCVV {
    padding-top: 4px;
}

.focusSpreedlyParent {
    box-sizing: border-box !important;
    background-clip: border-box;
    border-radius: 5px;
    outline: auto #005fcc 2px;
    outline-offset: -1px;
}

.legend {
    margin: 0;
    padding: 0;
    color: $vitusPrimaryOrange;
    grid-area: cvvLegend;
}

.dateAndCvvWrapper {
    margin-top: 10px;
    display: grid;
    grid-template-areas:
        'dateLabel cvvLegend'
        'dateInput cvv';
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
}

.cvvLabel {
    grid-area: cvv;
    margin: 0;
    height: 60px;
}

.submitBtn {
    margin: 0 auto;
}

/* ============ */
/* Util Classes */
/* ============ */

.invalid {
    outline: solid 2px $vitusRedColor;
    outline-offset: -1px;
}

/*
    This is to override the styles in _spreedly-overrides.scss
*/
@media screen and (max-width: 480px) {
    .spreedlyCVV,
    .spreedlyNumber {
        max-width: none !important;
        padding: 2px !important;
        border: 1px solid #808283 !important;
    }
}