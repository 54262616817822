.deviceListTable {
  border-bottom: 1px solid rgb(0, 0, 0);
  margin-bottom: 30px;
  &Body {
    .deviceIdItem {
      &Action {
        cursor: pointer;
      }
    }
  }
}
