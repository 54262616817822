@import 'assets/styles/_variables.scss';

.card {
    border: 2px solid transparent;
    border-radius: 5px;

    box-shadow: 0 3px 3px 1px rgba(0,0,0,.2);
}

.expanded {
    border-color: $vitusPrimaryOrange;
}
