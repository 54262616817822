.vitus-input-field {
    display: flex;
    align-items: center;
    background: white;
    padding: .35em 0;

    input {
        border-color: $vitusDetailBlue;
        background: white !important;
    }

    &.error {
        label { 
            color: $vitusRedColor;
        }

        input {
            border-color: $vitusRedColor;
        }
    }
}

.vitus-input-field-label { 
    position: absolute;
    left: .5rem;
    top: -9px;
    padding: 0 .25rem;
    background: white;
    font-size: 13px;
    color: $vitusPrimaryOrange;
}