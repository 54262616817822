@import 'src/assets/styles/_variables.scss';

.selectedContainer,
.unselectedContainer {
  border: 1px solid;
  border-radius: 10px;
  min-height: 4rem;
  padding: 1rem;
  display: grid;
  grid-template-columns: max-content auto;
  grid-template-areas: 'check content';
  column-gap: 1rem;
  align-items: center;
  text-align: left;
  background-color: inherit;
  width: 100%;
}

.selectedContainer:focus,
.unselectedContainer:focus {
  outline: none;
}

.selectedContainer {
  border-color: $vitusPrimaryBlue;
  background-color: $vitusPrimaryBlueTranslucent;
  box-shadow: 0 0 0 1px $vitusPrimaryBlue;
}

.unselectedContainer {
  border-color: $vitusLightGray;
}

.checkContainer {
  grid-area: 'check';
  display: flex;
  align-items: center;
  justify-content: center;
}

.check {
  height: 1.3rem;
  width: auto;
}

.content {
  grid-area: 'content';
}
