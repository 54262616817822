.payFabricHostedForm {
  display: flex;
  justify-content: center;
  height: 44.375rem;
}

.payFabricLoading {
  align-items: center;
  background-color: white;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  z-index: 999;
}

.textContainer {
  text-align: center;
}

.buttonContainer {
  display: flex;
  justify-content: space-around;
  height: 100%;
  text-align: center;
  width: 100%;
}
