.container {
  display: grid;
  grid-template-columns: 2fr 1.6fr 1.3fr 1.4fr;
  grid-template-areas: 'name cardType lastFour expiration';
  column-gap: 0.5rem;
  color: black;
}

.name {
  grid-area: 'name';
}

.cardType {
  grid-area: 'cardType';
}

.lastFour {
  grid-area: 'lastFour';
}

.expiration {
  grid-area: 'expiration';
}
