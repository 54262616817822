.no-data,
.box {
    box-sizing: border-box;
    .vitus-vet-box {
        background-color: #ffffff;
        padding: 1rem;
        border-radius: 5px;
        box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.2);
        h4 {
            margin: 0;
        }
    }
}

.no-data {
    margin: 2rem 0;
}

.box {
    margin: 1rem 1.5rem;
}
