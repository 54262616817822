.vitus-primary-blue {
    color: $vitusPrimaryBlue;
}

.vitus-text-blue { 
    color: $vitusTextBlue;
}

.vitus-detail-blue {
    color: $vitusDetailBlue;
}

.vitus-disabled-blue {
    color: $vitusDisabledBlue;
}

.vitus-primary-text-gray { 
    color: $vitusPrimaryTextGray;
}

.vitus-secondary-text-gray {
    color: $vitusSecondaryTextGray;
}

.vitus-primary-orange { 
    color: $vitusPrimaryOrange !important;
}

.vitus-red {
    color: $vitusRedColor
}

.vitus-green {
    color: $vitusGreenColor
}

.vitus-light-gray {
    color: $vitusLightGray;
}

.vitus-link-blue {
    color: $vitusLinkBlue;
}

.vitus-linked-value-blue {
    color: $vitusLinkedValueBlue;
}

.gap-1 {
    gap: 1rem;
}

.gap-2 {
    gap: 2rem;
}

.space-nowrap {
    white-space: nowrap !important;
}

.flex-basis-10 {
    flex-basis: 10%;
}

.pr-8 {
    padding-right: 8%;
}

.pl-8 {
    padding-left: 8%;
}

.pr-10 {
    padding-right: 10%;
}

.pl-10 {
    padding-left: 10%;
}

.min-width-200 {
    min-width: 200px;
}

.slide-right {
    animation-name: slideRight;
    animation-duration: 1.5s;
    animation-timing-function: ease;
}

.fluid {
    width: 100% !important;
}

.border-white { 
    border: solid 1px white;
}

@keyframes slideRight {
    from { transform: translateX(-20px) }
    to { transform: translateX(0) }
}

.slide-up {
    animation-name: slideUp;
    animation-duration: 1.5s;
    animation-timing-function: ease;
}

@keyframes slideUp {
    from { 
        transform: translateY(20px); 
        pointer-events: none; 
    }
    to { transform: translateY(0) }
}

.fade-in {
    animation-name: fadeIn;
    animation-duration: 1.5s;
    animation-timing-function: ease;
}

@keyframes fadeIn {
    0% { 
        opacity: 0; 
        pointer-events: none;
    }
    100% { opacity: 1; }
}