/*
    We could potentially override the boostrap style .is-invalid
    but that may effect other components. This way is scoped
*/
.isInvalid {
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
}

.infoText {
  display: 'block';
  color: '#000';
  line-height: '1.5em';
}

.infoTextInvalid {
  color: #dc3545;
}
