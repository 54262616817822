.sidebar {
    background-color: $vitusPrimaryBlue;
    min-width: 200px;
    max-width: 200px;
    min-height: 100vh;
    box-shadow: 3px 0 4px 0 #949194;
    transition: 0.3s;
    color: #fff;
    z-index: 3;

    .toggle-open {
        position: absolute;
        left: 200px;
        top: 0.9rem;
        color: $vitusPrimaryBlue;
        display: none;
    }

    .toggle-close {
        position: absolute;
        right: 0;
        color: #fff;
        display: none;
    }

    .sidebar-header {
        text-align: center;
        padding: 2rem 0;

        img {
            width: 160px;
        }
    }

    .user-info {
        padding: 1rem;

        .img {
            display: flex;
            justify-content: center;

            img {
                max-height: 60px;
                min-height: 60px;
                max-width: 60px;
                min-width: 60px;
                border-radius: 100%;
                border: 2px solid $vitusPrimaryOrange;
            }
        }

        .info {
            display: flex;
            align-items: center;

            .user-name {
                font-family: "Montserrat-Bold", sans-serif;
                color: #fff;
            }
        }

        .user-name {
            font-family: "Montserrat-Bold", sans-serif;
        }
    }

    .sidebar-list {
        margin: 0;
        list-style: none;
        padding: 1rem 0;

        li {
            a {
                color: #fff;
                display: block;
                padding: 1rem 1rem 1rem 1.5rem;
                font-family: "Montserrat-Bold", sans-serif;
                text-transform: uppercase;
                font-size: 0.9em;

                &:hover,
                &.active {
                    text-decoration: none;
                    color: $vitusPrimaryOrange;
                    background-color: $vitusBackgroundGray;
                }

                i {
                    font-size: 1em;
                }
            }
        }
    }

    .appversion {
        color: rgba(#ffffff, 0.2);
        bottom: 1rem;
        position: fixed;
        padding-left: 1rem;
        font-size: 0.7em;
        pointer-events: none;
    }

    .sidebar-container {
        position: sticky;
        top: 0;
    }
}

.overlay {
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    display: none;
}
