.sidebar {
  z-index: 11;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;

  .content {
    width: inherit;
  }
}

@media print {
  .sidebar,
  #launcher {
    visibility: hidden;
  }
}

@media (max-width: 950px) {
  .wrapper {
    position: relative;
    .sidebar {
      margin-left: -200px;
      position: absolute;

      .toggle-close {
        display: initial;
      }

      .toggle-open {
        display: initial;
      }

      &.active {
        margin-left: 0;
      }

      &:not(.active) {
        box-shadow: none;
      }
    }
    .overlay {
      display: initial;
      z-index: 2;
    }
  }
}
