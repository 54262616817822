.payFabricHostedForm {
  display: flex;
  justify-content: center;
  position: relative;
}

.payFabricHostedForm,
.payFabricHostedForm iframe {
  height: 600px;
}

.payFabricLoading {
  align-items: center;
  background-color: white;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  z-index: 999;
}
