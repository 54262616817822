.Login {
    height: 100vh;
    background-image: url('../../../assets/images/login-background.png');
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    .FormContainer {
        color: #FFF;
        font-family: 'Lato-Regular', sans-serif;
        max-width: 303px;
        box-sizing: border-box;
        
        a {
            color: #FFF;
            &:hover {
                text-decoration: underline;
            }
        }

        label {
            margin: 0;
        }
        
        .Logo {
            margin: 1rem 0;
            .CompanyLogo {
                height: 163px;
            }
        }
        
        .Welcome {
            font-weight: 700;
            
            h1 {
                margin: 0;
            }

            h1, h5 {
                color: #FFF;
                font-family: 'Lato-Medium', sans-serif;
            }
        }

        form {
            padding: 1rem 0.2rem;

            .FormOpts {
                font-size: 14px;
            }

            input.form-control {
                border-color: var(--light);
            }
        }
    }
}