.modal {
  height: 100vh;
  max-height: 100%;

  /*
    Rather than add a className prop to everything we can use this
    nested class attribute selector to target all the classes added
    by bootstrap
  */
  [class~='modal-content'] {
    margin-top: 0;
    height: 90vh !important;
  }

  [class~='modal-header'],
  [class~='modal-footer'] {
    flex: 0 0 auto;
  }

  [class~='modal-body'] {
    flex: 1 0 calc(90vh - 60px - 75px);

    * {
      font-family: 'Open Sans', sans-serif;
    }
  }
}

/*
  This media query is specifically for the iPhone
  The close button in the privacy policy modal is hidden
  by a bar that appears on the bottom of the phone's browser window
*/
@media only screen and (max-width: 475px) and (orientation: portrait) {
  .modal {
    [class~='modal-content'] {
      margin-top: -7.5vh;
      height: 85vh !important;
    }

    [class~='modal-body'] {
      flex: 1 0 calc(85vh - 60px - 75px);
    }
  }
}
