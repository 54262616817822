@import 'assets/styles/_variables.scss';

.container {
    margin: 1rem 0;

    display: flex;
    justify-content: center;
    align-items: center;

    button {
        border: none;
        font-size: 14px;
        font-family: "Lato-Heavy",sans-serif;
        color: $vitusPrimaryBlue;
        cursor: pointer;
    }

    button:not(:last-child) {
        margin-right: 10px;
    }
}

.page,
.currentPage {
    padding: 2px 10px;
    font-size: 14px;
    text-align: center;
}

.currentPage {
    color: #fff !important;
    background-color: $vitusPrimaryOrange;
    border-radius: 50%;
}