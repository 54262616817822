.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 5px;
    min-height: 100vh;
    background: url('../../../../assets/images/login-background.png') center center/cover;
}

.footer {
    display: flex;
    flex-direction: column;

    .footerBtn,
    .copyright {
        color: #fff;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
    }

    .copyright {
        font-size: 10px;
    }
}