h1, h2, h3 {
    color: $vitusPrimaryBlue;
    font-family: 'Montserrat-Bold', sans-serif;
}
h4, h5, h6 {
    color: $vitusPrimaryOrange;
}
h1 {
    font-size: 26px;
}
h2 {
    font-size: 20px;
}
h3 {
    font-size: 18px;
}

.info {
    h6 {
        font-size: 11px;
        font-weight: 600;
    }
    p, span, a, label, b {
        color: $vitusPrimaryBlue;
        font-size: 14px;
    }
}

.selectable {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.statusGreen {
    color: $statusGreen;
}

.statusOrange {
    color: $statusOrange;
}

.statusRed {
    color: $statusRed;
}

.statusNormal {
    color: $statusNormal;
}