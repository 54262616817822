.vitus-app-header {
    background-color: #FFF;
    box-shadow: 0 2px 4px 0 rgba(148, 145, 148, 0.41);
    min-height: 64px;
    z-index: 10;

    h1 {
        margin-bottom: 0;
        margin-left: 1.5rem;
    }

    .navbar-brand {
        font-family: 'Montserrat-Bold', sans-serif;
        font-size: 26px;
        margin-left: 1rem;
        color: $vitusPrimaryBlue;
    }

    .navbar-nav {
        background-color: $vitusBackgroundGray;
        border-radius: 50px;
        margin-right: auto;
        margin-left: 0.5rem;

        .nav-link {
            border-radius: 50px;
            padding-right: 1rem;
            padding-left: 1rem;
        }
    }

    .navbar-collapse {
        white-space: nowrap;

        .form-inline {
            gap: 8px;
        }
    }
    
    @media (min-width: 1200px){
        &.navbar-expand-lg {
            flex-flow: row nowrap;
            justify-content: flex-start;
        }
        
        &.navbar-expand-lg .navbar-collapse {
            display: flex !important;
            flex-basis: auto;
        }
       
        .navbar-toggler {
            display: none;
        }
    }

    @media (max-width: 1200px) {
        &.navbar {
            flex-wrap: wrap !important;
            justify-content: space-between !important;
        }

        .navbar-nav {
            flex-direction: row;
            flex-direction: column !important;
            background-color: white !important;
            margin-left: 0;
    
            .nav-link {
                background-color: white !important;
                color: $vitusPrimaryBlue;
                display: inline-block !important;

                &.active {
                    color: $vitusPrimaryOrange;
                }

                &:hover {
                    color: $vitusPrimaryOrange;
                }
            }
        }

        .navbar-toggler {
            display: inline !important;
        }

        .navbar-collapse {
            flex-basis: 100% !important;
            display: block !important;
            border-top: 1px solid $vitusBackgroundGray;
        }

        .navbar-collapse .form-inline {
            margin-top: 8px;
        }

        .collapse:not(.show) {
            display: none !important;
        }

        .navbar-collapse.collapse.show {
            border-top: 1px solid $vitusBackgroundGray;
            display: block !important;
        }
    }
}