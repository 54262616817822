@import '../../../../assets/styles/_variables.scss';

//mock form control styles for div
.spreedlyNumber,
.spreedlyCVV {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  display: flex;
  height: calc(1.5em + 0.75rem + 2px);
  border: 1px solid #83a2c2;
  background-color: #fafafa;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}

.checkboxContainer {
  display: flex;
  align-items: center;
}

.checkbox {
  width: 1rem;
  height: 1rem;
}

.checkboxLabel {
  margin: 0 1rem 0;
}
