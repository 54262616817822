.modal {
  width: 600px;
  max-width: 90vw;
}

.modalBody {
  min-height: 234px;
  display: flex;
  justify-content: center;
  padding-left: 3rem;
  padding-right: 3rem;
}

.payfabricModal {
  width: 500px;
}

.payfabricModalBody,
.payfabricModalBody iframe {
  height: 475px;
  padding: 0;
}

.customerNameContainer {
  margin-bottom: 2rem;
}

.spaceChildrenEvenly > * {
  margin-top: 0.5rem;
}

.spaceChildrenEvenly > *:first-child {
  margin-top: 0;
}

.spaceSideBySide {
  display: flex;
  justify-content: space-between;
}

.button {
  width: 5rem;
}

.disableArrows::-webkit-inner-spin-button,
.disableArrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.disableArrows {
  -moz-appearance: textfield;
}

.loadingSpinner {
  display: flex;
  justify-content: center;
  z-index: 100;
}

.hiddenInput {
  visibility: hidden;
  position: absolute;
}

.expirationCVVRow {
  display: grid;
  grid-template-areas: 'expiration cvv';
  grid-template-columns: 1fr 1fr;
  column-gap: 6rem;
}

.expirationContainer {
  grid-area: 'expiration';
}

.cvvContainer {
  grid-area: 'cvv';
}

.expirationFormContainer {
  display: flex;
  align-items: center;
}

.expirationSeparator {
  padding: 0 0.5rem 0;
}
