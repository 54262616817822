.buttonContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.centerSpinner {
  width: 100%;
  height: 100%;
  text-align: center;
}
