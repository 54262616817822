@import "../../../assets/styles/variables";

.errorCard {
  text-align: center;
  border: 1px solid $vitusPrimaryOrange;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  margin: 1rem 1.5rem;
  padding: 1rem;

  .jumbotronHeader {
    background-color: #ffffff;
    color: $vitusPrimaryOrange;

    .header {
      color: $vitusPrimaryOrange;
    }

    .errorIcon {
      font-size: 3em;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    }

    .display-4 {
      color: white;
    }
  }
}
