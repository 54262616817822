@import "../../../../../assets/styles/variables";

.transactionCard {
  background-color: #ffffff;
  border-radius: 10px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  margin: 1rem 1.5rem;
  padding: 1rem;
}
