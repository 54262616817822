.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: #fff;
}

.errorWrapper {
    padding: 2rem;
}

.errorHeading {
    text-align: center;
    margin-bottom: 2rem;
}

.errorButtons {
    display: flex;
    flex-direction: column;
}

.errorButtons button {
    margin-bottom: 2rem;
    width: 100%;
}