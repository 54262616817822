$vitusPrimaryBlue: #124474;
$vitusPrimaryBlueTranslucent: #83a2c226;
$vitusTextBlue: #33669d;
$vitusDetailBlue: #83a2c2;
$vitusDisabledBlue: #83a2c2;
$vitusLightGray: #c4c4c4e6;

$vitusPrimaryTextGray: #2c2e31;
$vitusSecondaryTextGray: #808283;
$vitusBackgroundGray: #f1f1f1;

$vitusPrimaryOrange: #ff9933;
$vitusHoverOrange: #ff8f32;
$vitusErrorOrange: #db7927;
$vitusDisabledOrange: #fdc286;

$vitusLinkBlue: #007bff;
$vitusLinkedValueBlue: #007bff;

$vitusRedColor: #e85a5a;
$vitusGreenColor: #4fd84f;
$vitusLightGray: #c8cbcd;

$statusGreen: #4fd84f;
$statusOrange: #ff9933;
$statusRed: #e85a5a;
$statusNormal: #808283;

:export {
  vitusPrimaryBlue: $vitusPrimaryBlue;
  vitusTextBlue: $vitusTextBlue;
  vitusDetailBlue: $vitusDetailBlue;
  vitusDisabledBlue: $vitusDisabledBlue;
  vitusPrimaryTextGray: $vitusPrimaryTextGray;
  vitusSecondaryTextGray: $vitusSecondaryTextGray;
  vitusBackgroundGray: $vitusBackgroundGray;
  vitusPrimaryOrange: $vitusPrimaryOrange;
  vitusHoverOrange: $vitusHoverOrange;
  vitusErrorOrange: $vitusErrorOrange;
  vitusDisabledOrange: $vitusDisabledOrange;
  vitusLinkBlue: $vitusLinkBlue;
  vitusLinkedValueBlue: $vitusLinkedValueBlue;
  vitusRedColor: $vitusRedColor;
  vitusGreenColor: $vitusGreenColor;
  vitusLightGray: $vitusLightGray;
  statusGreen: $statusGreen;
  statusOrange: $statusOrange;
  statusRed: $statusRed;
  statusNormal: $statusNormal;
}
