.CalendarDay__selected_span {

    background: $vitusTextBlue;
    color: white;
    border: 1px solid $vitusDetailBlue;
}
  
.CalendarDay__selected {
    background: $vitusPrimaryBlue;
    border: 1px double $vitusPrimaryBlue;
    color: white;
}
  
.CalendarDay__selected:hover {
    background: $vitusPrimaryBlue;
    border: 1px double $vitusPrimaryBlue;
    color: white;
}
  
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: $vitusTextBlue;
    border: 1px solid $vitusDetailBlue;
    color: white;
}

.CalendarDay__selected_span:active, 
.CalendarDay__selected_span:hover {
    background: $vitusPrimaryBlue;
    border: 1px double $vitusPrimaryBlue;
    color: #fff;
}

.DateInput_input__focused {
    border-bottom: 2px solid $vitusTextBlue; 
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid $vitusTextBlue;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid $vitusPrimaryBlue;
}

.DateRangePickerInput__withBorder {
    border: none;
}

.DateRangePickerInput__disabled {
    background: transparent;
}

.DateInput__disabled {
    background: transparent;
}

.DateInput_input__disabled {
    background: transparent;
    font-style: normal;
}

.DateInput_input {
    cursor: pointer;
}

.DateRangePickerInput_arrow_svg {
    fill: $vitusPrimaryBlue;
}

.DateInput {
    width: 135px;
}
