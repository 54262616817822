.vitus-refresher {
    .spinner-grow {
        height: 20px;
        width: 20px;
    }
    .vitus-refresher-icon {
        padding: 8px;
        border-radius: 50px;

        &:hover {
            cursor: pointer;
            background: $vitusBackgroundGray;
        }
    }
}