.linked-value {
    white-space: nowrap;
    
    .linked-value-copy-btn {
        display: none;
    }

    &.linked {
        cursor: pointer;
    }

    &.linked a {
        color: $vitusLinkedValueBlue !important;
    }

    &.expandable:hover { 
        position: absolute;
        background-color: white;
        border: 1px solid $vitusLightGray;
        padding: 8px;
        border-radius: 5px;
        z-index: 10000;

        .linked-value-copy-btn {
            display: inline;
            margin: 0 8px 0 16px;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &.show-ellipsis::after {
        content: '...';
        color: $vitusLinkedValueBlue;
    }

    &.show-ellipsis:hover::after {
        content: '';
    }

    &.underlined a {
        text-decoration: underline;
    }
}